<template>
  <section>
    <div class="row">
      <div class="col-md-6">
        <UsersTable
          :users="users"
          :formUser="formUser"
          :showSelected="showSelected"
          @changed="changed"
          @select="select"
          @unselect="unselect"
          @create="create"
        ></UsersTable>
      </div>
      <div class="col-md-6" v-if="formUser">
        <UserForm
          ref="userform"
          data-testid="user-form"
          :roles="roles"
          :processAreas="processAreas"
          :email="selectedEmail"
          @unselect="unselect"
          @changed="changed"
          @loading="(state) => this.$emit('loading', state)"
        ></UserForm>
      </div>
    </div>
  </section>
</template>
<script>
import UserForm from "./user-form.vue";
import UsersTable from "./users-table.vue";

export default {
  name: "UserDisplay",
  components: {
    UserForm,
    UsersTable
  },
  data: function() {
    return {
      selectedEmail: "",
      busy: false,
      formUser: false,
      showSelected: true
    };
  },
  computed: {
    users() {
      return this.$store.getters["user/users"];
    },
    roles() {
      return this.$store.getters["role/roles"];
    },
    processAreas() {
      return this.$store.getters["processArea/processAreas"];
    }
  },
  watch: {
    busy(n) {
      this.$emit("loading", n);
    }
  },
  methods: {
    create() {
      let self = this;
      self.unselect();
      self.formUser = false;
      self.$nextTick(function() {
        self.formUser = true;
      });
    },
    select(email) {
      this.selectedEmail = email;
      this.formUser = true;
      this.$nextTick(() => {
        if (this.$refs?.userform?.$el?.focus) {
          this.$refs.userform.$el.focus();
        }
      });
    },
    unselect() {
      this.showSelected = false;
      this.selectedEmail = "";
      this.formUser = false;
      this.$nextTick(() => {
        this.showSelected = true;
      });
    },
    changed() {
      let self = this;
      self.fetchUsers();
      self.unselect();
      self.busy = true;
      self.$nextTick(function() {
        self.busy = false;
      });
    },
    fetchUsers() {
      return this.$store.dispatch("user/fetchUsers", true);
    },
    fetchRoles() {
      return this.$store.dispatch("role/fetchRoles");
    },
    fetchProcessAreas() {
      return this.$store.dispatch("processArea/fetchProcessAreas");
    }
  },
  mounted: function() {
    this.busy = true;
    Promise.all([
      this.fetchUsers(),
      this.fetchProcessAreas(),
      this.fetchRoles()
    ]).then(() => (this.busy = false));
  }
};
</script>

<style scoped>
section > div.row > div:last-child {
  height: inherit;
}

section .users-table {
  height: 98%;
}
</style>
