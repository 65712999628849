<template>
  <form class="form" autocomplete="off" v-on:submit.prevent="submit">
    <div class="row">
      <div
        class="form-group"
        :class="
          !invite && can('contract_owner_can_disable_member')
            ? 'col-sm-9'
            : 'col-sm-12'
        "
      >
        <div>
          <label
            v-if="canInvite"
            class="no-select clicable"
            for="invite"
            @click.stop.prevent="toggleInvite"
          >
            <i :class="invite ? 'fa fa-check-square-o' : 'fa fa-square-o'"></i>
            <span> {{ $t("send_invitation") }} </span>
          </label>
          <label for="email" v-else> {{ $t("email") }}: </label>
          <Tooltip v-if="new_user" :title="$t('hints.member_email')" />
        </div>
        <input
          type="email"
          data-testid="email"
          name="email"
          required
          class="form-control"
          autocomplete="off"
          v-model="user.email"
          :disabled="!new_user || busy"
          :placeholder="$t('email')"
          ref="email"
        />
      </div>
      <div
        class="col-md-3 text-center"
        v-if="!invite && can('contract_owner_can_disable_member')"
      >
        <label class="no-select">{{ $tc("active") }}?</label>
        <div>
          <FormInputToggle
            v-bind:labels="$t('yes') + ',' + $t('no')"
            colors="#119056,#a0a0a0"
            fontSize="16"
            v-model="active"
          ></FormInputToggle>
        </div>
        <div class="hide-lg hide-md" style="margin-bottom: 22px" />
      </div>
    </div>
    <div class="row" v-if="invite">
      <div class="col-xs-12">
        <p class="small text-warning text-center">
          {{ $t("titles.invitation_info") }}
        </p>
      </div>
    </div>
    <div class="row" v-else>
      <div class="form-group col-md-6">
        <label for="firstName">{{ $t("first_name") }}: </label>
        <input
          type="text"
          data-testid="first-name"
          name="firstName"
          class="form-control"
          autocomplete="off"
          v-model="user.first_name"
          :disabled="busy || !can('contract_owner_can_assign_member_name')"
        />
      </div>
      <div class="form-group col-md-6">
        <label for="lastName">{{ $t("last_name") }}: </label>
        <input
          type="text"
          data-testid="last-name"
          name="lastName"
          class="form-control"
          autocomplete="off"
          v-model="user.last_name"
          :disabled="busy || !can('contract_owner_can_assign_member_name')"
        />
      </div>
    </div>
    <div
      class="row"
      v-if="!invite && can('contract_owner_can_assign_member_password')"
    >
      <div class="form-group col-md-12">
        <div class="">
          <input
            type="checkbox"
            class="form-check-input"
            id="blocks_password_change"
            v-model="blocks_password_change"
          />
          <label class="form-check-label" for="blocks_password_change">{{
            $t("disable_password_change")
          }}</label>
        </div>
      </div>
      <div class="form-group col-md-6">
        <input
          type="checkbox"
          class="form-check-input"
          id="require_password"
          v-model="require_password"
          v-bind:disabled="new_user || busy"
        />
        <label class="form-check-label" for="require_password"
          >{{ $t("password") }}
        </label>
        <input
          v-if="require_password"
          type="password"
          name="password1"
          required
          class="form-control"
          autocomplete="off"
          v-model="user.password"
          v-bind:disabled="busy"
          ref="password1"
        />
      </div>
      <div class="form-group col-md-6" v-if="require_password">
        <label for="password2">{{ $t("confirm_password") }} </label>
        <input
          type="password"
          name="password2"
          required
          class="form-control"
          autocomplete="off"
          v-model="password_confirm"
          v-bind:disabled="busy"
        />
      </div>
      <div class="form-group col-md-12 text-center" v-if="require_password">
        <div class="password-checkbox-field">
          <input
            type="checkbox"
            class="form-check-input"
            id="require_password_change"
            :disabled="blocks_password_change"
            v-model="require_password_change"
          />
          <label
            class="form-check-label"
            for="require_password_change"
            :class="blocks_password_change ? 'disabled' : ''"
            >{{ $t("require_password_change") }}</label
          >
          <div
            style="border-bottom: 1px solid #f4f4f4; margin: 15px 0 15px 0"
          ></div>
        </div>
      </div>
    </div>

    <template
      v-if="
        isNewPrivilegesEnabled &&
        !new_user &&
        $can('manage', 'MembroDeContratoEscrita')
      "
    >
      <div class="h4">
        {{ $t("privileges") }}
        <Tooltip :title="$t('hints.member_privileges')" />
      </div>
      <div class="row" style="margin-bottom: 5rem">
        <div class="col-md-12">
          <router-link
            :to="{
              path: '/dashboard/access-control/privileges/',
              query: { q: email }
            }"
            custom
            v-slot="{ navigate, href }"
          >
            <BaseButton type="primary" tag="a" :href="href" @click="navigate">
              {{ $tc("edit") }} {{ $tc("privileges").toLowerCase() }}
              <i class="fa fa-external-link"></i>
            </BaseButton>
          </router-link>
        </div>
      </div>
    </template>
    <template
      v-else-if="
        (!isNewPrivilegesEnabled || new_user) &&
        $can('manage', 'MembroDeContratoEscrita')
      "
    >
      <div class="h4">
        {{ $t("privileges") }}
        <Tooltip :title="$t('hints.member_privileges')" />
      </div>
      <div
        class="row"
        v-if="
          filteredProcessAreas.length &&
          $can('manage', 'MembroDeContratoEscrita')
        "
      >
        <div class="form-group col-md-12">
          <select
            class="form-control"
            data-testid="privileges"
            name="process_area"
            v-model="selected_process_area"
            v-on:change="addProcessArea()"
          >
            <option v-bind:value="null">
              {{ $t("select_the_process_area") }}
            </option>
            <option
              v-for="(item, index) in filteredProcessAreas"
              v-bind:key="index"
              v-bind:value="item"
            >
              {{ item.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="row" v-if="active_process_area != null">
        <div class="form-group col-md-6">
          <label for="process_area">
            {{ $tc("process_area", 2) }}:
            <Tooltip :title="$t('hints.member_process_area')" />
          </label>
          <ul class="list-group">
            <li
              class="list-group-item"
              v-for="(item, index) in input_process_area"
              v-bind:key="index"
              v-bind:class="{
                active: active_process_area && item.id == active_process_area.id
              }"
              v-on:click.prevent.stop="selProcessArea(item)"
              :title="
                item.id != active_process_area.id ? $t('titles.select') : ''
              "
            >
              <div class="process-area-item" data-testid="process-area">
                {{ item.name }}
              </div>
              <button
                v-if="
                  $can('manage', 'MembroDeContratoEscrita') &&
                  $can('manage', 'AreaDeProcessoAcesso')
                "
                class="btn btn-primary btn-xs"
                v-on:click.prevent.stop="delProcessArea(item)"
                :title="$t('titles.remove_process_area')"
              >
                <i class="fa fa-trash"></i>
              </button>
            </li>
          </ul>
        </div>
        <div
          class="form-group col-md-6"
          style="overflow: auto; margin-left: -5px"
        >
          <label for="roles"
            >{{ $tc("group", 2) }}:
            <Tooltip :title="$t('hints.member_groups')" />
          </label>
          <div
            class="form-check checkbox-groups"
            v-for="item in groups"
            v-bind:key="item.id"
          >
            <input
              type="checkbox"
              class="form-check-input"
              data-testid="group"
              v-bind:name="item.name"
              v-bind:id="item.name"
              v-bind:checked="isGroupChecked(item)"
              v-bind:disabled="
                !$can('manage', 'MembroDeContratoEscrita') ||
                !$can('manage', 'GrupoDeUsuariosAcesso')
              "
              v-on:click="checkGroup(item)"
            />
            <label class="form-check-label" v-bind:for="item.name">{{
              item.name
            }}</label>
          </div>
        </div>
      </div>
    </template>

    <div class="row">
      <div class="form-group col-xs-3">
        <button
          class="btn btn-default form-control"
          data-testid="cancel"
          v-on:click.stop.prevent="reset"
        >
          <i class="fa fa-undo"></i>
          <span class="hidden-xs"> {{ $t("cancel") }}</span>
        </button>
      </div>
      <div class="form-group col-xs-3">
        <button
          v-if="
            !invite &&
            user &&
            user.id &&
            $can('manage', 'MembroDeContratoCadastro')
          "
          class="btn form-control btn-default"
          data-testid="remove"
          v-on:click.stop.prevent="remove"
        >
          <i class="fa fa-trash"></i>
          <span class="hidden-xs"> {{ $t("delete") }}</span>
        </button>
      </div>
      <div class="form-group col-xs-3 col-xs-offset-3">
        <button
          v-if="$can('manage', 'MembroDeContratoEscrita')"
          class="btn form-control"
          data-testid="save"
          v-bind:class="{
            'btn-primary': isValid,
            'btn-default': !isValid
          }"
          v-bind:disabled="!isValid"
        >
          <i :class="invite ? 'fa fa-send' : 'fa fa-save'"></i>
          <span class="hidden-xs"> {{ $t(invite ? "confirm" : "save") }}</span>
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import UserService from "@/services/user.js";
import MixinAlert from "@/project/mixin-alert.js";
import Tooltip from "@/components/tooltip.vue";
import FormInputToggle from "@/components/registration/form-input-toggle";
import BaseButton from "@/components/base/buttons/base-button";

function initialState() {
  return {
    user: {
      email: "",
      first_name: "",
      last_name: "",
      is_active: true,
      picture: "",
      locale: "pt",
      password: ""
    },
    selected_process_area: null, // this is the item on selection box
    active_process_area: null, // this is the item on the list
    input_process_area: [],
    password_confirm: "",
    require_password: false,
    require_password_change: false,
    blocks_password_change: false,
    require_name: false,
    new_user: true,
    busy: false,
    invite: false
  };
}

export default {
  name: "UserForm",
  components: {
    Tooltip,
    FormInputToggle,
    BaseButton
  },
  props: {
    processAreas: {
      type: Array,
      required: true
    },
    roles: {
      type: Array,
      required: true
    },
    email: {
      type: String,
      required: false,
      default: () => ""
    }
  },
  mixins: [MixinAlert],
  data: function() {
    return {
      ...initialState(),
      isNewPrivilegesEnabled: this.$featureToggle.isFeatureEnabled(
        "new-access-control"
      )
    };
  },
  computed: {
    active: {
      set: function(v) {
        this.user.is_active = v == "1";
      },
      get: function() {
        return this.user.is_active ? "1" : "0";
      }
    },
    contract_id() {
      return this.$store.getters["user/loggedUser"].contract_id;
    },
    isUserNameRequired() {
      if (!this.new_user || this.invite) return false;
      return this.can("contract_owner_can_assign_member_name");
    },
    requirePasswordCheck() {
      if (this.invite) return false;
      return (
        this.can("contract_owner_can_assign_member_password") &&
        this.require_password
      );
    },
    isValid() {
      let loggedUser = this.$store.getters["user/loggedUser"];
      return (
        !this.busy &&
        (this.isUserNameRequired
          ? this.user.first_name && this.user.last_name
          : true) &&
        this.user.email &&
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          this.user.email
        ) &&
        (this.requirePasswordCheck
          ? this.user.password != "" &&
          this.user.password == this.password_confirm
          : true) &&
        (!this.new_user || this.input_process_area.length > 0) &&
        loggedUser &&
        "contract_id" in loggedUser
      );
    },
    filteredProcessAreas: function() {
      let self = this;
      let lst = (self.input_process_area || []).map(function(i) {
        return i.id;
      });
      return self.processAreas.filter(function(i) {
        return lst.indexOf(i.id) == -1;
      });
    },
    payload() {
      if (!this.user) return null;
      let process_areas = JSON.parse(JSON.stringify(this.input_process_area));
      let payload = {
        contract_id: this.contract_id,
        associated_permissions: (process_areas || []).map(function(area) {
          return {
            process_area_id: area.id,
            user_groups_ids: (area.user_groups || []).map((group) => group.id)
          };
        })
      };
      payload.email = this.user.email;
      if (this?.user?.id) {
        payload.id = this.user.id;
      }
      if (this?.user?.etag) {
        payload.etag = this.user.etag;
      }
      if (this.invite) {
        payload.create_user = this?.user?.id ? false : true;
        payload.create_invite = true;
      } else {
        payload.first_name = this.user.first_name || "";
        payload.last_name = this.user.last_name || "";
        if (this.can("contract_owner_can_disable_member")) {
          payload.is_active = this.user.is_active;
        }
        if (this.password_confirm) {
          payload.password1 = payload.password2 = btoa(this.password_confirm);
        }
        payload.requires_password_change = this.require_password_change;
        payload.blocks_password_change = this.blocks_password_change;
        if (
          this.new_user &&
          this.can("contract_owner_can_assign_member_password")
        ) {
          payload.create_user = this.new_user;
        }
      }
      return payload;
    },
    groups() {
      return this.roles.length
        ? this.roles
        : this.active_process_area?.user_groups ?? [];
    },
    canInvite() {
      return (
        this.can("contract_owner_can_invite_member") &&
        (this.new_user || this.active == "0")
      );
    }
  },
  watch: {
    busy(n) {
      this.$emit("loading", n);
    },
    email: {
      immediate: true,
      handler: function(n, o) {
        let self = this;
        self.initData();
        if (!n && self.$refs && self.$refs.email) {
          self.$nextTick(function() {
            if (self.$refs && self.$refs.email) {
              self.$refs.email.focus();
            }
          });
        }
      }
    },
    require_password(n) {
      if (n && !this.new_user) {
        this.$nextTick(() => {
          if (this.$refs.password1) {
            this.$refs.password1.focus();
          }
        });
      }
    },
    blocks_password_change(n) {
      if (n) {
        this.require_password_change = false;
      }
    }
  },
  methods: {
    can(what) {
      let cfg = this?.$root?.config?.user_management || {};
      return what in cfg ? cfg[what] : true;
    },
    reset: function() {
      let self = this;
      self.$emit("unselect");
      if (self.new_user) {
        self.initData();
      }
    },
    checkGroup: function(group) {
      let self = this;
      if (self.active_process_area) {
        if (
          self.active_process_area.user_groups.filter(function(i) {
            return i.id == group.id;
          }).length > 0
        ) {
          //remove
          self.active_process_area.user_groups = self.active_process_area.user_groups.filter(
            function(i) {
              return i.id != group.id;
            }
          );
        } else {
          // add
          self.active_process_area.user_groups.push(group);
        }
        let lst = self.input_process_area.filter(function(i, ix) {
          if (i.id == self.active_process_area.id) {
            self.input_process_area[ix] = self.active_process_area;
            return true;
          }
          return false;
        });
        if (!lst.length) {
          self.input_process_area.push(self.active_process_area);
        }
      }
    },
    isGroupChecked: function(group) {
      let self = this;
      if (self.active_process_area) {
        return (
          self.active_process_area.user_groups.filter(function(i) {
            return i.id == group.id;
          }).length > 0
        );
      }
      return false;
    },
    initData: function() {
      let self = this;
      let email = self.email;
      if (email) {
        self.new_user = false;
        let users = self.$store.getters["user/users"].filter(function(i) {
          if (i.email == email) {
            return true;
          }
        });
        if (users.length) {
          let user = JSON.parse(JSON.stringify(users[0]));
          self.user = user;
          if ("process_area" in user) {
            self.input_process_area = JSON.parse(
              JSON.stringify(user.process_area)
            );
            self.active_process_area = user.process_area.length
              ? user.process_area[0]
              : null;
          }
          this.blocks_password_change =
            self.user?.user_profile?.blocks_password_change || false;
          return;
        }
      } else {
        self.new_user = true;
      }
      let data = initialState();
      self.user = data.user;
      for (let p in data) {
        self[p] = data[p];
      }
      self.$nextTick(function() {
        if (self.$refs && self.$refs.email) {
          self.$refs.email.focus();
        }
      });
    },
    selProcessArea: function(entry) {
      let self = this;
      self.active_process_area = entry;
    },
    addProcessArea: function() {
      let self = this;
      if (self.selected_process_area) {
        let entry = JSON.parse(JSON.stringify(self.selected_process_area));
        entry.user_groups = [];
        self.input_process_area.push(entry);
        self.selected_process_area = null;
        self.active_process_area = entry;
      }
    },
    delProcessArea: function(item) {
      let self = this;
      let pos = -1;
      self.input_process_area = self.input_process_area.filter(function(i, ix) {
        if (i.id == item.id) {
          pos = ix;
          return false;
        } else {
          return true;
        }
      });
      self.selected_process_area = null;
      self.active_process_area = self.input_process_area.length
        ? self.input_process_area[pos - 1 > -1 ? pos - 1 : 0]
        : null;
    },
    deleteUser: function() {
      let self = this;
      let srv = new UserService();
      self.busy = true;
      srv.remove(self.payload).then(function(ret) {
        self.busy = false;
        if (ret) {
          if (typeof ret == "string") {
            self.alert = {
              title: self.$t("item_could_not_be_saved"),
              text: ret,
              type: "error"
            };
          } else {
            self.alert = {
              title: self.$t("deleted"),
              text: self.$t("you_have_deleted_n_items", { count: 1 }),
              type: "success"
            };
            self.$store.commit("user/RESET_USER_LIST");
            self.$emit("changed");
          }
          self.showAlert(function() {
            self.reset();
          });
        }
      });
    },
    remove: function() {
      let self = this;
      if (!(self.user.id && self.user.contract_id)) return;
      self
        .validatePrivileges("manage", "MembroDeContratoEscrita")
        .then((isOk) => {
          self
            .$swal({
              title: self.$t("are_you_sure"),
              text: self.$t("you_wont_be_able_to_revert_this"),
              type: "warning",
              buttons: [self.$t("cancel"), self.$t("yes_delete_it")]
            })
            .then(function(isConfirm) {
              if (isConfirm) {
                self.deleteUser();
              }
            });
        });
    },
    submit: function() {
      let self = this;

      self
        .validatePrivileges("manage", "MembroDeContratoEscrita")
        .then((isOk) => {
          if (!isOk) {
            return;
          }
          let payload = self.payload;
          let srv = new UserService();
          self.busy = true;
          const _cb = (ret) => {
            this.busy = false;
            if (this.validateSaveResponse(ret)) {
              this.$emit("changed");
              this.showAlert(() => {
                this.reset();
              }, true);
            } else {
              this.showAlert();
            }
          };
          srv.save(payload).then(_cb, _cb);
        });
    },
    toggleInvite() {
      this.invite = !this.invite;
      this.$nextTick(() => {
        if (this?.$refs?.email) this.$refs.email.focus();
      });
    },
    handleFeatureUpdate(features) {
      this.isNewPrivilegesEnabled = features["new-access-control"].enabled;
    }
  },
  mounted() {
    if (
      this.new_user &&
      this.can("contract_owner_can_assign_member_password")
    ) {
      this.require_password = true;
    }
    this.$featureToggle.addUpdateListener(this.handleFeatureUpdate);
  },
  beforeDestroy() {
    this.$featureToggle.removeUpdateListener(this.handleFeatureUpdate);
  }
};
</script>

<style scoped>
.form-check-label {
  vertical-align: top;
  margin-left: 5px;
}
.form-check-label:hover {
  cursor: pointer;
}

ul.list-group > li.list-group-item {
  overflow: hidden;
}

ul.list-group > li.list-group-item:hover {
  cursor: pointer;
}

ul.list-group > li.list-group-item > button {
  position: absolute;
  top: 10px;
  right: 10px;
}

.process-area-item {
  position: relative;
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
}

.checkbox-groups {
  white-space: nowrap;
}

.form-control:focus:invalid {
  border-color: #e42d2d;
}

.password-checkbox-field {
  display: inline-block;
}

.password-checkbox-field:last-child {
  margin-left: 15px;
}

label.disabled {
  color: gray;
}
label.disabled:hover {
  cursor: not-allowed;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}
.clicable:hover {
  opacity: 0.8;
  cursor: pointer;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}
</style>
